import React, { useState } from "react";
import CardWithAction from "../components/CardWIthAction";
import SEO from "../components/seo";
import Layout from "../components/layout";
import WhatWeDontTreat from "../components/WhatWeDontTreat";
import PreventativeHealth from "../components/PreventativeHealth";
import ChronicCare from "../components/ChronicCare";
import { Link } from "gatsby";
import SwyftDocLogo from "../images/SwyftDocLogo.svg";
import { PATIENT_WEBSITE } from "../constants";

export default function WhatWeTreat() {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = trackingTitleString => {
    window.analytics.track(trackingTitleString);
  };
  return (
    <Layout>
      <SEO title="SwyftDoc" />

      <div className="relative overflow-hidden bg-gray-50">
        <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
          <div className="relative h-full max-w-screen-xl mx-auto">
            <svg
              className="absolute transform right-full translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="svg-pattern-squares-1"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#svg-pattern-squares-1)"
              />
            </svg>
            <svg
              className="absolute transform left-full -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="svg-pattern-squares-2"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#svg-pattern-squares-2)"
              />
            </svg>
          </div>
        </div>

        <div
          x-data="{ open: false }"
          className="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32"
        >
          <div className="max-w-screen-xl px-4 mx-auto sm:px-6">
            <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
              <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="#">
                    <img
                      className="w-auto h-8 sm:h-10"
                      src={SwyftDocLogo}
                      alt="SwyftDoc Logo"
                    />
                  </a>
                  <div className="flex items-center -mr-2 md:hidden">
                    <button
                      //   @click="open = true"
                      onClick={() => {
                        setIsOpen(true);
                        console.log("byeee");
                      }}
                      type="button"
                      className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                    >
                      <svg
                        className="w-6 h-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <Link
                  to="/"
                  className="font-medium text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900"
                >
                  Home
                </Link>
                <Link
                  to="/howitworks"
                  className="ml-10 font-medium text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900"
                >
                  How it works
                </Link>
                <Link
                  to="/whatwetreat"
                  className="ml-10 font-medium text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900"
                >
                  What we treat
                </Link>
                {/* <a
                  href="#"
                  className="ml-10 font-medium text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900"
                >
                  Company
                </a> */}
              </div>
              <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                <span className="inline-flex rounded-md shadow">
                  <Link
                    href={PATIENT_WEBSITE}
                    onClick={() => {
                      handleClick("Whatwetreat->PatientWebsite");
                    }}
                    className="inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-indigo-600 transition duration-150 ease-in-out bg-white border border-transparent rounded-md hover:text-indigo-500 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-indigo-700"
                  >
                    Get started
                  </Link>
                </span>
              </div>
            </nav>
          </div>

          <div
            // x-show="open"
            // style={isOpen ? { display: "absolute" } : { display: "none" }}
            className={
              isOpen ? "absolute inset-x-0 top-0 p-2 md:hidden" : "hidden"
            }
            // className="absolute inset-x-0 top-0 p-2 md:hidden"
          >
            <div
              className="transition origin-top-right transform rounded-lg shadow-md"
              x-show="open"
              // x-transition:enter="duration-150 ease-out"
              // x-transition:enter-start="opacity-0 scale-95"
              // x-transition:enter-end="opacity-100 scale-100"
              // x-transition:leave="duration-100 ease-in"
              // x-transition:leave-start="opacity-100 scale-100"
              // x-transition:leave-end="opacity-0 scale-95"
            >
              <div className="overflow-hidden bg-white rounded-lg shadow-xs">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <img
                      className="w-auto h-8"
                      src={SwyftDocLogo}
                      alt="SwyftDoc Logo"
                    />
                  </div>
                  <div className="-mr-2">
                    <button
                      //   @click="open = false"
                      onClick={() => {
                        setIsOpen(!isOpen);
                        console.log("Hi");
                      }}
                      type="button"
                      className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                    >
                      <svg
                        className="w-6 h-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3">
                  <Link
                    to="/"
                    className="block px-3 py-2 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  >
                    Home
                  </Link>
                  <Link
                    to="/howitworks"
                    className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  >
                    How it works
                  </Link>
                  <Link
                    to="/whatwetreat"
                    className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  >
                    What we treat
                  </Link>
                </div>
                <div>
                  <a
                    href={PATIENT_WEBSITE}
                    onClick={() => {
                      handleClick("WhatWeTreat->PatientWebsite");
                    }}
                    className="block w-full px-5 py-3 font-medium text-center text-indigo-600 transition duration-150 ease-in-out bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-screen-xl px-4 mx-auto mt-10 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
            <div className="text-center">
              <h2 className="text-4xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                What we
                <br className="xl:hidden" />
                <span className="text-indigo-600"> treat</span>
              </h2>
              <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                When you’re sick and need to see a doctor, our team is standing
                by around the clock. Available 24/7, our providers can help get
                you on track as well as order prescriptions, if needed. We’ll
                take the hassle and guesswork out of feeling better.
              </p>
            </div>
          </div>
          <div className="p-4 mx-auto sm:p-20">
            <CardWithAction
              title="Urgent Care"
              buttonTitle="Book appointment"
            />
          </div>

          <div className="p-4 mx-auto sm:p-20">
            <PreventativeHealth
              title="Preventative Health"
              buttonTitle="Book appointment"
            />
          </div>
          <div className="p-4 mx-auto sm:p-20">
            <ChronicCare title="Chronic Care" buttonTitle="Book appointment" />
          </div>

          <div className="p-4 mx-auto sm:p-20" id="whatwedonttreat">
            <WhatWeDontTreat />
          </div>
        </div>
      </div>
    </Layout>
  );
}
